import React from "react"
import { Link } from "gatsby"
import css from "@emotion/css"
export default function OrdinacijaSilvija() {
  return (
    <>
      <div className="py-2 text-center bg-gray-100">
        <div className="container">
          <Link to="/" className="text-gray-900">
            {" "}
            <h1 className="text-lg font-semibold sm:text-2xl ">
              Ordinacija opće/obiteljske medicine Smiljevac
            </h1>
          </Link>
        </div>
      </div>

      <div className="bg-blue-200">
        <div className="container pt-5 pb-12">
          <h4 className="mb-5 text-center">
            Vrste i cijene zdravstvenih usluga koje Ustanova naplaćuje izvan
            osnovne mreže javne zdravstvene službe:
          </h4>
          <table
            className="mx-auto bg-white border-t-4 border-blue-600 shadow "
            css={css`
              th,
              td {
                padding: 0.5rem 1rem;
                border: 1px solid #e2e8f0;
              }
              tr:nth-child(:even) {
                background: #000;
              }
            `}
          >
            <thead>
              <tr>
                <td style={{ verticalAlign: "middle" }}>
                  <span>
                    <strong>Opis postupka / usluge</strong>
                  </span>
                </td>
                <td className="text-right">
                  <span>
                    <strong>Cijena</strong>
                  </span>
                </td>
              </tr>
            </thead>
            <thead />
            <tbody>
              {Cijene.map((c, i) => {
                const key = Object.keys(c)[0]
                const value = parseFloat(c[key])
                console.log(key, value)
                return (
                  <tr key={i}>
                    <td>
                      <span>{key}</span>
                    </td>
                    <td className="text-right">
                      <span>
                        {Intl.NumberFormat("hr", {
                          style: "currency",
                          currency: "EUR",
                        }).format(value)}{" "}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <footer className="bg-blue-900">
        <div className="container py-2 text-center text-white">
          Ordinacija opće / obiteljske medicine Smiljevac ©{" "}
          {new Date().getFullYear()}
        </div>
      </footer>
    </>
  )
}
const List = [
  "Prvi pregled LOM* u kući",
  "Kontrolni pregled LOM* u kući",
  "Prvi pregled SOM* u kući",
  "Kontrolni pregled SOM* U kući",
  "Prvi pregled LOM* u ordinaciji",
  "Kontrolni pregled LOM* u ordinaciji ",
  "Prvi pregled SOM* u ordinaciji",
  "Kontrolni pregled SOM* u ordinaciji",
  "Mjerenje tlaka",
  "Profil glukoze u krvi",
  "Mjerenje tjelesne težine, tjelesne visine, opseg struka, opseg bokova, BMI - po postupku",
  "EKG sa interpretacijom",
  "Pretraga urina UROKOMB 9 trakom ",
  "Otoskopija",
  "Ispiranje cerumena",
  "Uzimanje venske krvi za laboratorijske pretrage",
  "Uzimanje biološkog materijala za mikrobiološke pretrage - po brisu",
  "Uzimanje urina za analizu",
  "Spirometrija sa interpretacijom",
  "Peek flow metrija",
  "Rinoskopija",
  "Fluorescein test",
  "Test raspoznavanja boja - Ishihara test ",
  "Pretraga ''Wood'' lampom",
  "Injekcija s.c.",
  "Injekcija i.m.",
  "Injekcija i.v.",
  "Infuzija",
  "Primjena inhalacija putem inhalatora ",
  "Punkcija zgloba/burze i evakuacija sadržaja",
  "Primarna opskrba manjih rana",
  "Opskrba veće rane uz lokalnu anesteziju i šivanje",
  "Intraartikularna aplikacija lijeka (''blokada'')",
  "Odstranjivanje šavova ili kopči",
  "Odstranjivanje stranog tijela iz oka, uha ili nosa",
  "Odstranjivanje nokta ili dijela nokta uz lokalnu anesteziju",
  "Kateterizacija mokraćnog mjehura ili zamjena trajnog katetera",
  "Previjanje manje rane",
  "Previjanje veće rane",
  "Obrada kronične rane – spec. obloge",
  "Incizija gnojnih procesa kože, 2 previjanja uključena",
  "Imobilizacija",
  "Lavaža želuca",
  "Digitorektalni pregled",
  "Klizma",
  "Uvođenje i zamjena trahealne kanile",
  "Toaleta kolostome i zamjena vrećice",
  "Zaustavljanje krvarenja iz nosa",
  "Kompletna anti-šok terapija",
  "TENS (jedna aplikacija do 10 min.)",
  "Mali sistematski pregled",
  "Ekspertiza sa stručnim mišljenjem 1 IN (obrada za IK-u i sl.)",
  "Izdavanje recepta/uputnice, ispričnice bez pregleda",
  "Izdavanje raznih vrsta liječničkih potvrda i pismenih mišljenja za sud i sl.",
  "Konzultacija",
  "Timska konzultacija – konzultacija s više profesija uz pisano mišljenje",
]
const Iznosi = [
  "53.09",
  "26.54",
  "79.63",
  "39.82",
  "26.54",
  "13.27",
  "53.09",
  "26.54",
  "3.98",
  "13.27",
  "1.33",
  "13.27",
  "6.64",
  "6.64",
  "13.27",
  "6.64",
  "66.36",
  "3.98",
  "19.91",
  "9.29",
  "6.64",
  "9.29",
  "6.64",
  "13.27",
  "5.31",
  "7.96",
  "10.62",
  "13.27",
  "6.64",
  "26.54",
  "19.91",
  "26.54",
  "29.20",
  "6.64",
  "19.91",
  "26.54",
  "26.54",
  "6.64",
  "13.27",
  "33.18",
  "33.18",
  "15.93",
  "19.91",
  "6.64",
  "13.27",
  "6.64",
  "6.64",
  "19.91",
  "53.09",
  "9.29",
  "53.09",
  "66.36",
  "6.64",
  "23.89",
  "13.27",
  "33.18",
]

const Cijene = List.map((l, i) => {
  return {
    [l]: Iznosi[i],
  }
})